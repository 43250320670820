<template>
  <validation-observer
      ref="form"
      #default="{ invalid }"
      tag="form"
      class="repeater__form"
      style="overflow: visible"
  >
    <b-modal
        v-model="show"
        content-class="package-modal rounded-8"
        dialog-class="wameed-modal-md"
        no-fade
    >
      <template slot="modal-header-close">
        <close-icon/>
      </template>
      <template slot="modal-title">
        <div class="d-block text-center">
          <h3 class="text-reg-18">
            {{ modalData != null ? $t('vehicles.update_btn') : $t('vehicles.new_btn') }}
          </h3>
        </div>
      </template>


      <b-row>
        <b-col md="6" v-for="locale in locales" :key="locale">
          <text-input
              v-model="form['name_'+locale]"
              :label="$t('vehicles.form.name_'+locale)"
              :placeholder="$t('form.text.placeholder')"
              name="package-ad"
              rules="required"
          />
        </b-col>

        <b-col cols="12" md="6">
          <TextInput
              v-model="form.min_delivery_price"
              rules="required"
              field-classes="w-100"
              :label="$t('settings.general_setting.min_delivery')"
              :placeholder="$t('form.text.placeholder')"
              is-append
              type="number"
              :append-text="$t('common.rial')"
          />
        </b-col>
        <b-col cols="12" md="6">
          <TextInput
              v-model="form.add_price_per_km"
              rules="required"
              field-classes="w-100"
              :label="$t('settings.general_setting.add_per_km')"
              :placeholder="$t('form.text.placeholder')"
              is-append
              type="number"
              :append-text="$t('common.rial')"
          />
        </b-col>

        <b-col md="12">

          <wameed-input-file-upload
              fileType="images"
              field-classes="w-100"
              rule="required"
              functionType="front"
              v-model="form.icon.name"
              :label="$t('vehicles.form.icon')"
              :input-placeholder="$t('form.image.placeholder')"
              :input-append-text="$t('form.image.browse')"
              @changed="uploadProfileImage"
          />
        </b-col>

      </b-row>

      <template slot="modal-footer">
        <wameed-btn
            classes="  text-med-14 text-white mx-0 rounded-8"
            :title="modalData!=null ?$t('btn.update'):$t('btn.add')"
            :disabled="invalid"
            type="submit"
            variant="main"
            @onClick="submitOrder()"
        />
        <wameed-btn
            classes="  text-med-14 text-font-secondary mx-0 rounded-8 "
            :title="$t('btn.cancel')"
            type="button"
            variant="gray"
            @onClick="closeModal()"
        />
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import {WameedBtn, WameedTextInput as TextInput} from 'wameed-ui/dist/wameed-ui.esm';
import {mapActions} from 'vuex';
import {availableLocales} from '@/enums/locales.enum'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    WameedBtn,
    TextInput,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    modalData: {
      type: Object,
      default: null,
    },

  },

  data() {
    return {
      locales: availableLocales,
      form: {
        icon: '',
        add_price_per_km: '',
        min_delivery_price: '',
      },
      showSuccessModal: false,
    };
  },
  computed: {

    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  watch: {
    modalData(newVal) {
      if (newVal != null) {
        this.form = newVal;
      } else {
        this.form.name = '';
      }
    },
  },
  created() {
    if (this.modalData != null) {
      this.form = this.modalData
    }
  },
  methods: {
    ...mapActions({
      create: "admin/settings/vehicles/create",
      update: "admin/settings/vehicles/update",
    }),
    uploadProfileImage(value) {
      this.form.icon = value;
    },


    submitOrder() {

      if (this.modalData == null) {
        this.create(this.form).then(() => {
          this.$emit('onComplete', true);
          this.closeModal();
          this.$store.commit('loadingFinish', null, {root: true});
        });
      } else {
        this.update({
          ...this.form,
          id: this.modalData.id,

        }).then(() => {
          this.$emit('onComplete', true);
          this.closeModal();
          this.$store.commit('loadingFinish', null, {root: true});
        });
      }
    },


    closeModal() {
      this.form = {
        icon: '',
      };
      this.show = false;
    },
  },
};
</script>
